@import '../../theme/theme.scss';

.table-container {
    overflow-x: auto;
    width: 100%;
  }
  
  .table-container::-webkit-scrollbar {
    height: 8px;
    width: 5px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background: #2d7070;
    border-radius: 4px;
  }
  
  .table-container::-webkit-scrollbar-thumb:hover {
    background: #22737d;
  }

  .custom-select {
    width: 100%;
    padding: 0;
  }
  
  .custom-select .MuiSelect-select {
    padding: 8px 4px;
  }
  
  .custom-select .MuiSelect-icon {
    right: 4px;
  }

  /* Change heading color */
.custom-header .MuiDataGrid-columnHeader {
  background-color: $primary-color;  /* Change this to your desired heading color */
}

/* Change scroll bar color */
.custom-scrollbar {
  /* Webkit-based browsers */
  scrollbar-width: thin;  /* For Firefox */
  scrollbar-color: #576957 #f0f0f0;  /* Scrollbar color and track color for Firefox */

  /* Webkit-based browsers */
  &::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #576957; /* Scrollbar thumb color */
    border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Scrollbar track color */
  }
}

.centered-cell {
  text-align: center;
}